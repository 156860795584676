import { isEqual, xor } from "lodash";
import { GroupingKeys } from "./Dfg";
import { ActivityItem } from "./EventKeys";
import { BaseQuantityType } from "./ApiTypes";

export const timeFilterRenderType = "timeFilter";
export const productFilterRenderType = "productFilter";
export const abcFilterRenderType = "abcFilter";
export const caseIdFilterRenderType = "caseIdFilter";
export const caseYieldFilterRenderType = "caseYieldFilter";
export const activityFilterRenderType = "activityFilter";

export type FilterEditState = {
    editFilter: EventFilter | undefined;
    editFilterIndex: number | undefined;
    editFilterPage?: number;
    showFilterEditor: boolean;
};

export type AbcProductFilter = EventFilter & ProductFilter & {
    abcState: AbcStateType;
};

export type AbcStateType = {
    quantity: string;
    statistic: string;
    selection: AbcProductStateType;
};

export type AbcProductStateType = [boolean, boolean, boolean];

/**
 * Top level filter definition
 */
export type Filter = {
    filters: FilterEntry[];
    mergeOperator?: "and" | "or";
    caseAggregator?: "all" | "any";
    renderType?: string;
}


/**
 * Single filter definition
 */

export type FilterEntry = Filter | EventFilter;

export enum FilterOrigins {
    User,
    Rca,
}

/**
 * This function is used for tracking event filters only at the moment
 */
export function getFilterFriendlyName(filter: EventFilter) {
    if (filter.renderType === productFilterRenderType)
        return "product";

    if (filter.renderType === caseIdFilterRenderType)
        return "case";

    if (filter.caseTime)
        return "time";

    if (filter.variant)
        return "variant";

    if (filter.caseDuration)
        return "duration";

    if (filter.renderType === caseYieldFilterRenderType)
        return "caseYield";

    if (filter.renderType === activityFilterRenderType)
        return "activity";

    if (filter.caseSequence)
        return "sequence";

    if (filter.caseAttributeBool ||
        filter.caseAttributeDatetime ||
        filter.caseAttributeFloat ||
        filter.caseAttributeInt ||
        filter.caseAttributeText)
        return "attribute";
}

/**
 * Filter type. At least one of it's properties needs to be valid, otherwise the filter will
 * be ignored.
 */
export type EventFilter = Partial<Filter> & {
    activity?: {
        ne?: ActivityItem[],
        eq?: ActivityItem[],

        /**
         * This is used by the frontend only to store the grouping this filter relates to
         */
        groupingKey?: GroupingKeys;
    },
    caseAttributeDatetime?: AttributeFilter<string>;
    caseAttributeInt?: AttributeFilter<number>;
    caseAttributeFloat?: AttributeFilter<number>;
    caseAttributeText?: AttributeFilter<string>;
    caseAttributeBool?: AttributeFilter<boolean>;
    caseDuration?: CaseDurationFilter;
    caseSequence?: CaseSequenceFilter;
    caseTime?: CaseTimeFilter;
    variant?: VariantFilter;

    filters?: EventFilter[];
    mergeOperator?: "and" | "or";
    caseAggregator?: "all" | "any";
    renderType?: string;
    origin?: FilterOrigins;
};

export type BomFilter = {
    upstreamLevels?: number;
    downstreamLevels?: number;
};

export type AttributeFilter<T> = {
    name: string;
    billOfMaterials?: BomFilter;
    ge?: T | undefined;
    gt?: T | undefined;
    le?: T | undefined;
    lt?: T | undefined;
    eq?: T[] | undefined;
    ne?: T[] | undefined;
    [key: string]: T | T[] | BomFilter | undefined | string;
};

/**
 * Filters traces and removes those that either miss a specific activity and/or contain another.
 */
export type ActivityFilter = {
    eq?: ActivityItem[];
    ne?: ActivityItem[];
};

/**
 * Filter that filters cases by products or product categories. Not known to the API, needs to
 * be converted to a DTO.
 */
export type ProductFilter = {
    /**
     * Dictionary category id => list of category values that pass the filter
     */
    categories: { [categoryId: string]: (string | number | boolean)[] };
};

/**
 * Filters traces and removes those that don't contain the specified activities in the given
 * sequence.
 */
export type CaseSequenceFilter = {
    /**
     * Array of traces that are all allowed
     */
    eq?: ActivityItem[][];

    /**
     * Array of traces that should all be removed from the result set
     */
    ne?: ActivityItem[][];

    /**
     * Enforce that the two activities must be in direct sequence
     */
    enforceImmediateSequence?: boolean;

    /**
     * If set to true, events within the same work sequence (pass) are grouped together. 
     * For basic time a event operations, the pass events act as a single virtually lengthend
     * event that spans all span events. Work sequences (passes) are often defined by planning
     * systems and often are labeled in steps of 10 (40, 50, 60 for example).
     */
    consolidatePasses?: boolean;

    /**
     * Used in the frontend only. This is the grouping key used by this filter instance.
     */
    groupingKey: GroupingKeys;
};

/**
 * Filter that removes traces that are shorter then minDuration (if specified) or take
 * longer than maxDuration (if specified).
 */
export type CaseDurationFilter = {
    /**
     * Minimum duration, in seconds
     */
    ge?: number;

    /**
     * Maximum duration, in seconds
     */
    lt?: number;

    /**
     * used by the frontend to display the initial unit used correctly
     */
    minUnitId?: string;

    /**
     * used by the frontend to display the initial unit used correctly
     */
    maxUnitId?: string;

    /**
     * If true, the duration range is excluded from the results.
     */
    exclude?: boolean;
};

/**
 * Filter that removes traces that violate a time range
 */
export type CaseTimeFilter = {
    /**
     * If true, only traces are passing that start in the range specified.
     * Defaults to true.
     */
    requireStartInRange?: boolean;

    /**
     * If true, only traces are passing that end in the range specified
     * Defaults to true.
     */
    requireEndInRange?: boolean;

    /**
     * Start timestamp of the range. If omitted, the interval has no lower bound.
     */
    ge?: string;

    /**
     * End timestamp of the range. If omitted, the interval has no upper bound.
     */
    lt?: string;

    /**
     * If true, the duration range is excluded from the results.
     */
    exclude?: boolean;

};

export type VariantFilter = {
    /**
     * Match any value equal to one of the items in this list.
     */
    eq?: string[];

    /**
     * Match values that do not equal any of the items in this list.
     */
    ne?: string[];

    /**
     * If set to true, events within the same work sequence (pass) are grouped together.
     * For basic time a event operations, the pass events act as a single virtually
     * lengthend event that spans all span events.Work sequences (passes) are often
     * defined by planning systems and often are labeled in steps of 10 (40, 50,
     * 60 for example).
     */
    consolidatePasses?: boolean;

    /**
     * List of activity keys to use for variant calculation
     */
    activityKeysGroup?: GroupingKeys;

    /**
     * Used by the frontend only
     */
    sortBy?: string;

    /**
     * Used by the frontend only
     */
    sortAscending?: boolean;

    /**
     * Used by the frontend only
     */
    quantity?: BaseQuantityType;
}

export function isFilterEqual(a: EventFilter | undefined, b: EventFilter | undefined): boolean {
    if (!a && !b)
        return true;

    if (!a || !b)
        return false;

    return isActivityFilterEqual(a.activity, b.activity) &&
        isCaseAttributeEqual<boolean>(a.caseAttributeBool, b.caseAttributeBool) &&
        isCaseAttributeEqual<number>(a.caseAttributeFloat, b.caseAttributeFloat) &&
        isCaseAttributeEqual<number>(a.caseAttributeInt, b.caseAttributeInt) &&
        isCaseAttributeEqual<string>(a.caseAttributeText, b.caseAttributeText) &&
        isCaseAttributeEqual<string>(a.caseAttributeDatetime, b.caseAttributeDatetime) &&
        isCaseDurationEqual(a.caseDuration, b.caseDuration) &&
        isCaseSequenceEqual(a.caseSequence, b.caseSequence) &&
        isCaseTimeEqual(a.caseTime, b.caseTime) &&
        (isEqual((a as AbcProductFilter)?.abcState, (b as AbcProductFilter)?.abcState)) &&
        isVariantFilterEqual(a.variant, b.variant) &&
        a.mergeOperator === b.mergeOperator &&
        a.caseAggregator === b.caseAggregator &&
        a.renderType === b.renderType &&
        ((!a.filters?.length && !b.filters?.length) ||
            (a.filters!.length === b.filters!.length &&
                a.filters!.every((_, idx) => isFilterEqual(a.filters![idx], b.filters![idx]))));
}

function isVariantFilterEqual(a: VariantFilter | undefined, b: VariantFilter | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return a.activityKeysGroup === b.activityKeysGroup &&
        a.sortAscending === b.sortAscending &&
        a.sortBy === b.sortBy &&
        a.consolidatePasses === b.consolidatePasses &&
        a.quantity === b.quantity &&
        isArrayEqual<string>(a.eq, b.eq) &&
        isArrayEqual<string>(a.ne, b.ne);
}

function isCaseTimeEqual(a: CaseTimeFilter | undefined, b: CaseTimeFilter | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return a.ge === b.ge &&
        a.lt === b.lt &&
        a.requireEndInRange === b.requireEndInRange &&
        a.requireStartInRange === b.requireStartInRange &&
        a.exclude === b.exclude;
}

function isCaseSequenceEqual(a: CaseSequenceFilter | undefined, b: CaseSequenceFilter | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    if (a.enforceImmediateSequence !== b.enforceImmediateSequence)
        return false;

    const isSequenceEqual = (sequenceA: ActivityItem[][] | undefined, sequenceB: ActivityItem[][] | undefined) => {

        if (sequenceA === undefined && sequenceB === undefined) return true;

        if (!sequenceA || !sequenceB) return false;
        if (sequenceA.length !== sequenceB.length) return false;

        for (let i = 0; i < sequenceA.length; i++)
            if (!isEqual(sequenceA[i], sequenceB[i]))
                return false;

        return true;
    };

    return isSequenceEqual(a.eq, b.eq) && isSequenceEqual(a.ne, b.ne);
}

function isCaseDurationEqual(a: CaseDurationFilter | undefined, b: CaseDurationFilter | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return a.ge === b.ge && a.lt === b.lt && a.exclude === b.exclude;
}

function isCaseAttributeEqual<T>(a: AttributeFilter<T> | undefined, b: AttributeFilter<T> | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return a.ge === b.ge &&
        a.gt === b.gt &&
        a.le === b.le &&
        a.lt === b.lt &&
        a.name === b.name &&
        a.billOfMaterials?.downstreamLevels === b.billOfMaterials?.downstreamLevels &&
        a.billOfMaterials?.upstreamLevels === b.billOfMaterials?.upstreamLevels &&
        isArrayEqual<T>(a.eq, b.eq) &&
        isArrayEqual<T>(a.ne, b.ne);
}

function isArrayEqual<T>(a: T[] | undefined, b: T[] | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return xor(a, b).length === 0;
}

function isActivityFilterEqual(a: ActivityFilter | undefined, b: ActivityFilter | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return areActivityItemsEqual(a.eq, b.eq) && areActivityItemsEqual(a.ne, b.ne);
}

function areActivityItemsEqual(a: ActivityItem[] | undefined, b: ActivityItem[] | undefined) {
    if (!a && !b) return true;
    if (!a || !b) return false;

    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        const aElement = a[i];
        const bElement = b[i];

        if (xor(aElement.keys, bElement.keys).length > 0)
            return false;

        if (xor(aElement.values, bElement.values).length > 0)
            return false;
    }

    return true;
}